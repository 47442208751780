<template>
  <div class="popup-container">
    <div class="popup-content">
      <div class="close-img-wrap" @click="handleClose">
        <img :src="`${webImageUrlPrefix}close.png`" />
      </div>
      <div class="sell-img-wrap">小卖部</div>
      <div class="price-btns">
        <div class="coin-assets">
          <span>{{userinfo.coin_count > 9999 ? (userinfo.coin_count / 10000).toFixed(1) + 'w' : userinfo.coin_count}}</span>
        </div>
        <div class="jewel-assets">
          <span>{{userinfo.jewel_count > 9999 ? (userinfo.jewel_count / 10000).toFixed(1) + 'w' : userinfo.jewel_count}}</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="main">
        <div class="boss-img-wrap">
          <img :src="`${webImageUrlPrefix}boss.png`" />
        </div>
        <div class="buy-content">
          本次购买需花费
          <span class="highlight">{{buyCoinPrice}}Co币</span>或
          <span class="highlight">{{buyJewelPrice}}Co钻</span>哦～
          <br />请选择购买方式：
        </div>
      </div>

      <div class="buy-btns">
        <div class="coin-price" @click="buyShop(0)">
          <span>{{buyCoinPrice}}</span>
          <img :src="`${webImageUrlPrefix}coin.png`" />
          <span>购买</span>
        </div>
        <div class="jewel-price" @click="buyShop(1)">
          <span>{{buyJewelPrice}}</span>
          <img :src="`${webImageUrlPrefix}jewel.png`" />
          <span>购买</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../config'

export default {
  props: ['userinfo', 'buyCoinPrice', 'buyJewelPrice'],
  data() {
    return {
      webImageUrlPrefix: Config.webImageUrlPrefix,
    }
  },
  methods: {
    buyShop(val) {
      this.$emit('buy', {
        currency_type: val
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  $rem: 6.67px;
  @return ($px/$rem) + rem;
}

$webImageUrlPrefix:  'https://image.co-study.com.cn/web/user-figure/';

.popup-content {
  width: px2rem(380px);
  height: px2rem(214px);
  background: #fefbf6;
  border-radius: px2rem(17px);
  border: px2rem(3px) solid #e9c47c;
  position: relative;
  right: constant(safe-area-inset-left);
  right: env(safe-area-inset-left);

  .close-img-wrap {
    width: px2rem(38px);
    height: px2rem(38px);
    position: absolute;
    top: px2rem(-16px);
    right: px2rem(-16px);

    img {
      width: 100%;
    }
  }

  .sell-img-wrap {
    width: px2rem(97px);
    background: url("#{$webImageUrlPrefix}sell.png");
    background-size: 100%;
    position: absolute;
    top: px2rem(-24px);
    left: px2rem(7px);
    font-size: px2rem(14px);
    font-weight: 600;
    color: #fefbf6;
    line-height: px2rem(68px);
    text-align: center;
  }

  .price-btns {
    display: flex;
    position: absolute;
    top: px2rem(7px);
    right: px2rem(27px);

    .coin-assets,
    .jewel-assets {
      width: px2rem(94px);
      line-height: px2rem(43px);
      font-size: px2rem(12px);
      color: #015982;

      span {
        display: inline-block;
        margin-left: px2rem(30px);
        width: px2rem(55px);
        transform: scale(0.85);
        text-align: center;
      }
    }

    .coin-assets {
      background: url("#{$webImageUrlPrefix}popup-coin-bg.png");
      background-size: 100%;
      margin-right: px2rem(13px);

      span {
        color: #965e31;
      }
    }

    .jewel-assets {
      background: url("#{$webImageUrlPrefix}popup-jewel-bg.png");
      background-size: 100%;

      span {
        color: #015982;
      }
    }
  }

  .line {
    width: px2rem(341px);
    border-top: 1px dotted #e9c47c;
    margin: px2rem(47px) auto px2rem(24px);
  }

  .main {
    display: flex;
    align-items: center;
    margin-left: px2rem(7px);

    .boss-img-wrap {
      width: px2rem(61px);

      img {
        width: 100%;
      }
    }

    .buy-content {
      width: px2rem(278px);
      // height: px2rem(60px);
      height:fit-content;
      background: #fff;
      border-radius: px2rem(10px);
      font-size: px2rem(14px);
      box-shadow: 0px 0px 4px 0px rgba(209, 159, 117, 0.18);
      color: #57301a;
      line-height: px2rem(24px);
      padding: px2rem(7px) 0 px2rem(5px) px2rem(15px);
      box-sizing: border-box;
      position: relative;
      margin-left: px2rem(8px);
      // white-space: nowrap;

      .highlight {
        color: #febe4e;
      }
    }

    .buy-content::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-width: px2rem(7rem);
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      top: px2rem(11px);
      left: px2rem(-13px);
    }
  }

  .buy-btns {
    display: flex;
    justify-content: center;
    margin: px2rem(17px) auto 0;

    .coin-price,
    .jewel-price {
      height: px2rem(25px);
      display: flex;
      align-items: center;
      font-size: px2rem(14px);
      background: #965e31;
      border-radius: px2rem(5px);
      color: #fffbf5;
      padding: 0 px2rem(10px);

      span {
        color: #f9ef9c;
      }

      img {
        width: px2rem(14px);
        height: px2rem(14px);
        margin: 0 px2rem(3px);
      }
    }

    .coin-price {
      margin-right: px2rem(80px);
    }
  }
}
</style>
