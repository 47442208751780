<template>
  <!-- <div id="app" :style="appStyle"> -->
  <div id="app">
    <!-- v-if="!coinPopupVisible && !buyPopupVisible" -->
    <div class="container">
      <img v-show="scene === 'sit'" class="desk-img" :src="`${imageUrlPrefix}boy/desk_sit_1.png`" />

      <div v-show="scene === 'stand'">
        <img
          class="stand-img"
          v-for="item in standDrawData"
          :key="item.id"
          :src="`${imageUrlPrefix}${item.images.stand}`"
        />
      </div>

      <div v-show="scene === 'sit'">
        <img
          class="sit-img"
          v-for="item in sitDrawData"
          :key="item.id"
          :src="`${imageUrlPrefix}${item.images.sit}`"
        />
      </div>

      <canvas style="display: none" ref="standDraw" id="standCanvas" width="1125" height="1125"></canvas>
      <canvas style="display: none" ref="sitDraw" id="sitCanvas" width="1125" height="1125"></canvas>

      <!-- <canvas
        v-show="scene === 'stand'"
        style="transform: scale(0.33);transform-origin: 0 0;"
        ref="standDraw"
        id="standCanvas"
        width="1125"
        height="1125"
      ></canvas>
      <canvas
        v-show="scene === 'sit'"
        style="transform: scale(0.33);transform-origin: 0 0;"
        ref="sitDraw"
        id="sitCanvas"
        width="1125"
        height="1125"
      ></canvas>-->

      <div class="back" @click="goBack">
        <img :src="`${webImageUrlPrefix}back.png`" />
      </div>

      <div class="left-container">
        <div class="switch-scene" @click="switchScene">切换场景</div>
        <div @click="coinPopupVisible = true" class="coin">
          <span>{{userinfo.coin_count > 9999 ? (userinfo.coin_count / 10000).toFixed(1) + 'w' : userinfo.coin_count}}</span>
        </div>
        <div class="jewel" @click="goATM">
          <span>{{userinfo.jewel_count > 9999 ? (userinfo.jewel_count / 10000).toFixed(1) + 'w' : userinfo.jewel_count}}</span>
        </div>
        <div v-if="!needBuy" class="save-image" @click="isCanImage">保存形象</div>
        <div v-else class="buy-all" @click="buyPopup()">全身购买</div>
      </div>

      <div v-if="needBuy" class="price">
        <span class="total-price">总价</span>
        <img class="coin" :src="`${webImageUrlPrefix}coin.png`" />
        <span class="coin-price">{{countCoinPrice}}</span>
        <img class="jewel" :src="`${webImageUrlPrefix}jewel.png`" />
        <span class="jewel-price">{{countJewelPrice}}</span>
      </div>

      <div class="right-container" style="z-index:99">
        <div class="right-header">形象</div>
        <div class="main">
          <div class="main-header">
            <div
              class="type-item"
              v-for="(item, index) in categoryList"
              :key="item.type"
              @click="switchCategory(index)"
            >
              <img :src="selectedCategory === index ? item.selectedIconUrl : item.iconUrl" />
            </div>
          </div>
          <div v-if="tabIndex === 0" class="image-box">
            <div
              v-if="selectedCategory === 8 || (selectedCategory === 3 && userinfo.sex !== 2) || (selectedCategory === 5 && userinfo.sex === 2) || selectedCategory === 6"
              class="sell-item"
              @click="selectWear()"
            >
              <div
                class="sell-item-image"
                :class="!selectedWear[selectedCategory] ? 'selected-item' : '' "
              >
                <img :src="`${webImageUrlPrefix}no.png`" />
              </div>
            </div>
            <div
              v-for="item in wardrobeList"
              class="image-item"
              :key="item.id"
              @click="selectWear(item)"
            >
              <div
                class="image-item-wrap"
                :class="(selectedWear[item.category_id] && (selectedWear[item.category_id] === item.id)) ? 'selected-item' : '' "
              >
                <img :src="`${imageUrlPrefix}${item.images.shop}`" />
              </div>
            </div>
          </div>
          <div v-if="tabIndex === 1" class="image-box">
            <div
              v-if="selectedCategory === 8 || (selectedCategory === 3 && userinfo.sex !== 2) || (selectedCategory === 5 && userinfo.sex === 2) || selectedCategory === 6"
              class="sell-item"
              @click="selectWear()"
            >
              <div
                class="sell-item-image"
                :class="!selectedWear[selectedCategory] ? 'selected-item' : '' "
              >
                <img :src="`${webImageUrlPrefix}no.png`" />
              </div>
            </div>
            <div
              v-for="item in shopList"
              :key="item.id"
              class="sell-item"
              @click="selectWear(item)"
            >
              <div
                class="sell-item-image"
                :class="(selectedWear[item.category_id] && (selectedWear[item.category_id] === item.id)) ? 'selected-item' : '' "
              >
                <img :src="`${imageUrlPrefix}${item.images.shop}`" />
              </div>
              <div v-if="item.is_buy" class="purchased">已拥有</div>
              <div @click="buyPopup(item)" v-if="!item.is_buy" class="sell-bi-price">
                <img :src="`${webImageUrlPrefix}coin.png`" />
                <span>{{item.coin_price}}</span>
              </div>
              <div @click="buyPopup(item)" v-if="!item.is_buy" class="sell-zuan-price">
                <img :src="`${webImageUrlPrefix}jewel.png`" />
                <span>{{item.jewel_price}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-box">
          <div class="wardrobe" :class="tabIndex === 0 ? 'btn-active' : ''" @click="switchTab(0)">衣橱</div>
          <div class="shop" :class="tabIndex === 1 ? 'btn-active' : ''" @click="switchTab(1)">小卖部</div>
        </div>
      </div>

      <van-loading v-show="loadingShow" type="spinner" color="#333">保存中...</van-loading>
    </div>
    <buy-popup
      v-if="buyPopupVisible"
      :userinfo="userinfo"
      :buyCoinPrice="buyCoinPrice"
      :buyJewelPrice="buyJewelPrice"
      @buy="buyShop"
      @close="buyPopupVisible = false"
    />
    <coin-popup v-if="coinPopupVisible" @close="coinPopupVisible = false" />
  </div>
</template>

<script>
import OSS from 'ali-oss'
import axios from 'axios'
import BuyPopup from './components/buyPopup'
import CoinPopup from './components/coinPopup'
import Config from './config'
import Utils from './util/util'
import categoryList from './config/category'
import http from './model/http'
import Apis from './model/apis'

export default {
  name: 'App',
  components: {
    BuyPopup,
    CoinPopup
  },
  data() {
    return {
      canSave: false,   // 当前是否可保存图片
      webImageUrlPrefix: Config.webImageUrlPrefix,
      timeStr: '',
      loadingShow: false,
      sitIsDrawed: true,  // canvas是否绘制完毕,坐
      standIsDrawed: true,   //canvas是否绘制完毕,站
      saving: false,    // 是否正在保存
      scene: 'stand',    // 当前场景，默认是站着
      imageUrlPrefix: Config.imageUrlPrefix,    // 图片前缀
      isAssetsChange: false,   // 资产信息是否变动，点击返回时给客户端用
      isImageChange: false,    // 是否保存形象，点击返回时给客户端用
      sitImage: '',    // 坐着的图片，即canvas生成的图片
      standImage: '',   // 站着的图片，即canvas生成的图片
      needBuy: false,    // 当前形象中，是否有需要购买的
      drawData: [],   // 画布,所有图像以此数据为基础
      standDrawData: [],    // 站
      sitDrawData: [],    // 坐
      countCoinPrice: 0,   // 当前形象未购买穿搭co币总价
      countJewelPrice: 0,   // 当前形象未购买穿搭co钻总价
      buyShopId: null,   // 购买某项单品，该单品id
      buyCoinPrice: 0,   // 需付co币
      buyJewelPrice: 0,    // 需付co钻
      selectedCategory: 0,    // 当前选中类别，默认为全部
      tabIndex: 1,    // 当前选中tab，默认为衣橱
      categoryList,  // 所有类别
      userinfo: {},   // 用户信息
      shopList: [],    // 商店列表
      wardrobeList: [],    // 衣橱列表
      selectedWear: {},    // 已经选择的穿搭
      coinPopupVisible: false,   // co币弹窗是否显示
      buyPopupVisible: false,    // 购买弹窗是否显示
      boyDefault: [
        {
          category_id: 'body',
          stand_level: 2,
          sit_level: 2,
          images: {
            stand: "boy/body_stand_1.png",
            sit: "boy/body_sit_1.png"
          }
        },
        {
          category_id: 'head',
          stand_level: null,
          sit_level: 4,
          images: {
            sit: "boy/head_sit_1.png"
          }
        },
        {
          category_id: 'book',
          stand_level: null,
          sit_level: 8,
          images: {
            sit: "boy/book_on_desktop.png"
          }
        }
      ],
      girlDefault: [
        {
          category_id: 'body',
          stand_level: 2,
          sit_level: 2,
          // sit_level: 1,  // 发型放前面
          images: {
            stand: "girl/body_stand_1.png",
            sit: "girl/body_sit_1.png"
          }
        },
        {
          category_id: 'head',
          stand_level: null,
          sit_level: 4,
          images: {
            sit: "girl/head_sit_1.png"
          }
        },
        {
          category_id: 'book',
          stand_level: null,
          sit_level: 8,
          images: {
            sit: "boy/book_on_desktop.png"
          }
        }
      ],
    }
  },
  watch: {
    async drawData(val) {
      await this.filterStandDrawData()   // 更新站立数据
      await this.filterSitDrawData()    // 更新坐着数据
      await this.getBuyInfo(val)   // 更新购买信息
      this.canSave = true
    },
    canSave(val) {
      if (this.saving && val) {
        this.saving = false
        this.drawing()
      }
    },
    sitIsDrawed(val) {
      if (val && this.standIsDrawed) {
        this.saveImage()
      }
    },
    standIsDrawed(val) {
      if (val && this.sitIsDrawed) {
        this.saveImage()
      }
    },
    scene(val) {
      const bgUrl = val === 'sit' ? `${this.imageUrlPrefix}boy/background_sit_1.png` : `${this.imageUrlPrefix}boy/background_stand_1.png`
      const imageSize = `calc( 100% + env(safe-area-inset-bottom) )`
      document.querySelector('body').setAttribute('style', `background:url(${bgUrl}) 0% 0% / auto ${imageSize} no-repeat #fffcf5;`)
    }
  },
  async mounted() {
    await this.getUserinfo()
    await this.getShopList()
    this.getOssConfig()
  },
  methods: {
    // 点击形象进行换装
    selectWear(item) {
      this.canSave = false
      const drawData = this.drawData
      // item不存在意味着点击了脱下该类形象，只有装扮和男孩发型可以
      if (!item) {
        this.$set(this.selectedWear, this.selectedCategory, '')
        drawData.forEach((drawItem, index) => {
          if (drawItem.category_id === this.selectedCategory) {
            drawData.splice(index, 1)
          }
        })
        this.drawData = [...drawData]
        return
      }
      // 点击的单品已经穿戴，则不做任何处理
      if (this.selectedWear[item.category_id] === item.id) {
        this.canSave = true
        return
      }
      this.$set(this.selectedWear, item.category_id, item.id)
      const has = drawData.some((drawItem, index) => {
        if (drawItem.category_id === item.category_id) {
          drawData.splice(index, 1, item)
        }
        return drawItem.category_id === item.category_id
      })
      if (!has) {
        drawData.push(item)
      }
      this.drawData = [...drawData]
    },
    // 获取oss配置
    async getOssConfig() {
      const res = await axios.get(`${Config.coURL}v1/oss_token`)
      this.client = new OSS({
        region: 'oss-cn-shanghai',
        accessKeyId: res.data.AccessKeyId,
        accessKeySecret: res.data.AccessKeySecret,
        bucket: 'co-study',
        stsToken: res.data.SecurityToken
      })
    },
    //  获取用户信息
    async getUserinfo() {
      const res = await http.get(Apis.getUserinfo)
      if (res) {
        this.userinfo = res.data
        await this.getWardrobeList()
        if (this.drawData.length === 0) {
          this.initDrawData()
        }
      }
    },
    // 获取衣橱列表
    async getWardrobeList() {
      const res = await http.get(Apis.getWardrobeList, {
        params: {
          category_id: this.selectedCategory,
          sex: this.userinfo.sex === 2 ? 0 : 1
        }
      })
      if (res) {
        this.wardrobeList = res.data.records
      }
    },
    // 获取商店列表
    async getShopList() {
      const res = await http.get('v2/image/shop/records', {
        params: {
          category_id: this.selectedCategory,
          sex: this.userinfo.sex === 2 ? 0 : 1
        }
      })
      if (res) {
        this.shopList = res.data.records
      }
    },
    // 形象初始化
    initDrawData() {
      let drawData = []
      if (this.userinfo.sex === 2) {   // 女生
        drawData = drawData.concat(this.girlDefault)
      } else {   // 男生
        drawData = drawData.concat(this.boyDefault)
      }
      const selectedWear = this.selectedWear
      this.wardrobeList.forEach(item => {   // 遍历衣橱列表，in_use为1表示已穿戴
        if (item.in_use) {
          drawData.push(item)
          selectedWear[item.category_id] = item.id
        }
      })
      this.selectedWear = { ...selectedWear }
      this.drawData = drawData
    },
    // 当前穿戴所需要的购买的信息
    getBuyInfo(val) {
      let countCoinPrice = 0,
        countJewelPrice = 0,
        needBuy
      val.forEach(item => {
        if (item.is_buy === 0) {
          needBuy = true
          countCoinPrice += item.coin_price
          countJewelPrice += item.jewel_price
        }
      })
      this.countCoinPrice = countCoinPrice
      this.countJewelPrice = countJewelPrice
      this.needBuy = needBuy
    },
    // 绘图
    drawImage(drawData, ctx, index, scene) {
      let img = new Image()
      img.setAttribute("crossOrigin", 'anonymous')
      img.src = `${this.imageUrlPrefix}${drawData[index].images[scene]}?v=${Math.random()}`
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 1125, 1125)
        if (drawData.length > index + 1) {
          this.drawImage(drawData, ctx, index + 1, scene)
        } else {
          if (scene === 'sit') {
            this.sitIsDrawed = true
          } else {
            this.standIsDrawed = true
          }
        }
      }
    },
    async drawing() {
      this.sitIsDrawed = false
      this.standIsDrawed = false
      const standCtx = this.$refs.standDraw.getContext('2d')
      const sitCtx = this.$refs.sitDraw.getContext('2d')
      // 清空画布,重新绘制
      standCtx.clearRect(0, 0, 1125, 1125)
      sitCtx.clearRect(0, 0, 1125, 1125)
      await this.drawImage(this.standDrawData, standCtx, 0, 'stand')
      await this.drawImage(this.sitDrawData, sitCtx, 0, 'sit')
    },
    switchCategory(index) {   // 选择类别
      this.selectedCategory = index
      if (this.tabIndex === 0) {    // 当前tab选中衣橱，请求衣橱列表
        this.getWardrobeList()
      } else {    // 当前tab选中商店，请求商店列表
        this.getShopList()
      }
    },

    switchTab(index) {    // 切换tab,需重新请求数据
      this.tabIndex = index
      this.selectedCategory = 0
      if (index === 0) {
        this.getWardrobeList()
      } else {
        this.getShopList()
      }
    },

    buyPopup(item) {    // 弹起购买弹窗
      this.buyPopupVisible = true
      if (item) {
        this.buyJewelPrice = item.jewel_price
        this.buyCoinPrice = item.coin_price
        this.buyShopId = item.id
      } else {
        this.buyJewelPrice = this.countJewelPrice
        this.buyCoinPrice = this.countCoinPrice
        this.buyShopId = ''
      }
    },

    async buyShop({ currency_type }) {
      let ids = ''
      if (this.buyShopId) {
        ids = this.buyShopId
      } else {
        this.getBuyIds().forEach(item => {
          if (!item) return
          ids += `${item},`
        })
        ids = ids.substring(0, ids.length - 1)
      }
      const res = await http.post(Apis.shopBuy, {
        ids,
        currency_type
      })
      if (res) {
        this.$toast({ message: '购买成功', className: 'toast-trans' })
        this.buyShopId = ''
        this.buyPopupVisible = false
        const drawData = this.drawData
        let idsArr = ids.toString().split(',')
        drawData.forEach(item => {
          if (idsArr.includes(item.id + '')) {
            item.is_buy = 1
          }
        })
        this.drawData = [...drawData]
        const wardrobeList = this.wardrobeList
        wardrobeList.forEach(item => {
          if (idsArr.includes(item.id + '')) {
            item.is_buy = 1
          }
        })
        this.wardrobeList = [...wardrobeList]
        this.isAssetsChange = true
        this.getUserinfo()
      }
    },

    getBuyIds() {
      const ids = this.drawData.map(item => {
        return item.is_buy === 0 ? item.id : ''
      })
      return ids
    },

    isCanImage() {
      this.loadingShow = true
      if (!this.canSave) {
        this.saving = true
        return
      } else {
        this.drawing()
      }
    },

    async saveImage() {    // 保存形象
      try {
        const standImage = await this.getImage('standDraw')
        const sitImage = await this.getImage('sitDraw')
        let ids = ''
        this.getImageIds().forEach(item => {
          if (!item) return
          ids += `${item},`
        })
        ids = ids.substring(0, ids.length - 1)
        const sit_img = sitImage.name.substring(17)
        const stand_img = standImage.name.substring(17)
        const sex = this.userinfo.sex === 2 ? 0 : 1
        this.sitImage = sit_img
        this.standImage = stand_img

        const res = await http.post(Apis.saveImage, {
          ids,
          sit_img,
          stand_img,
          sex
        })
        this.loadingShow = false
        if (res) {
          this.$toast({ message: '你的形象已保存', className: 'toast-trans' })
          this.isImageChange = true
        }
      } catch (e) {
        this.loadingShow = false
      }
    },

    getImageIds() {
      const ids = this.drawData.map(item => {
        return item.id ? item.id : ''
      })
      return ids
    },

    async getImage(scene) {
      try {
        const file = this.$refs[scene].toDataURL()
        const fileType = file.split(';')[0].split('/')[1]
        // const currentTime = new Date().getTime().toString().substring(0, 10)
        const currentTime = new Date().getTime()
        const random = Utils.getRandomStr(5)
        const num = scene === 'sitDraw' ? 8 : 7
        const name = `app/image/figure/${currentTime}_${random}_${num}_${this.userinfo.id}.${fileType}`
        const blob = this.dataURLtoBlob(file)
        let ossRes = await this.client.put(name, blob)
        return ossRes
      } catch (err) {
        // alert(err)
        this.$toast({ message: err.toString(), className: 'toast-trans' })
      }
    },

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },

    filterStandDrawData() {    // 过滤出站立时所需要的图片，并根据图层顺序进行排序
      // 即认为stand_level字段有值时，就表示站立形象需要绘制这张图片，不需要则传null
      const filterStandDrawData = this.drawData.filter(item => {
        return item.stand_level
      })
      // 根据图层顺序进行排序
      const standDrawData = filterStandDrawData.sort((preItem, nextItem) => {
        return preItem.stand_level - nextItem.stand_level
      })
      this.standDrawData = standDrawData
    },

    filterSitDrawData() {    // 过滤出坐着时所需要的图片，并根据图层顺序进行排序
      // 即认为sit_level字段有值时，就表示坐形象需要绘制这张图片，不需要则传null
      const filterSitDrawData = this.drawData.filter(item => {
        return item.sit_level
      })
      console.log(filterSitDrawData)
      // 根据图层顺序进行排序
      const sitDrawData = filterSitDrawData.sort((preItem, nextItem) => {
        return preItem.sit_level - nextItem.sit_level
      })
      console.log(sitDrawData)
      this.sitDrawData = sitDrawData
    },

    goBack() {    // 点击返回按钮，与客户端进行交互
      const isIosOrAndroid = Utils.isIosOrAndroid()
      const params = {
        isAssetsChange: this.isAssetsChange,   // Boolean
        isImageChange: this.isImageChange,    // Boolean
        image: { figure_sit: this.sitImage, figure_stand: this.standImage }
      }
      if (isIosOrAndroid === 'android') {
        window.Android.goBack(JSON.stringify(params))
      } else {
        window.webkit.messageHandlers.goBack.postMessage(JSON.stringify(params))
      }
    },

    // 点击co钻跳转到充值页面
    goATM() {
      const isIosOrAndroid = Utils.isIosOrAndroid()
      // const params = {
      // }
      if (isIosOrAndroid === 'android') {
        window.Android.goATM()
      } else {
        window.webkit.messageHandlers.goATM.postMessage('')
      }
    },

    switchScene() {    // 切换场景
      this.scene = this.scene === 'sit' ? 'stand' : 'sit'
    },
  }
}
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  $rem: 6.67px;
  @return ($px/$rem) + rem;
}

$webImageUrlPrefix: "https://image.co-study.com.cn/web/user-figure/";

.container /deep/ .van-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
}
.container /deep/ .van-loading__text {
  color: #595959;
}
/* 竖屏 */
@media screen and (orientation: portrait) {
  .stand-img,
  .desk-img,
  .sit-img {
    width: 100vw;
  }
}
/* 横屏 */
@media screen and (orientation: landscape) {
  .stand-img,
  .desk-img,
  .sit-img {
    width: 100vh;
  }
}
.container {
  position: relative;
  width: 100%;
  height: 100%;

  .desk-img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
  }

  .stand-img,
  .sit-img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .back {
    width: px2rem(30px);
    height: px2rem(30px);
    display: flex;
    justify-content: center;
    position: absolute;
    top: px2rem(19px);
    left: 0;
    z-index: 99;

    img {
      height: 100%;
    }
  }

  .left-container {
    position: absolute;
    left: 0;
    bottom: px2rem(42px);
    z-index: 99;

    .switch-scene {
      width: px2rem(67px);
      height: px2rem(23px);
      border-radius: px2rem(11px);
      background: #fdfef0;
      box-shadow: 0px 1px 2px 0px rgba(93, 61, 33, 0.35);
      font-size: px2rem(12px);
      color: #cf9b82;
      line-height: px2rem(23px);
      text-align: center;
      margin-left: px2rem(5px);
      margin-bottom: px2rem(28px);
    }

    .coin,
    .jewel {
      width: px2rem(75px);
      height: px2rem(30px);
      line-height: px2rem(30px);
      font-size: px2rem(10px);
      color: #fff;

      span {
        display: block;
        transform: scale(0.8);
        margin-left: px2rem(27px);
      }
    }

    .coin {
      background: url("#{$webImageUrlPrefix}coin-bg.png") no-repeat;
      background-size: 100%;
      // margin-bottom: px2rem(8px);
    }

    .jewel {
      background: url("#{$webImageUrlPrefix}jewel-bg.png") no-repeat;
      background-size: 100%;
      margin-bottom: px2rem(29px);
    }

    .save-image,
    .buy-all {
      width: px2rem(71px);
      height: px2rem(23px);
      background: #e9d7b3;
      border-radius: px2rem(4px);
      border: 2px solid #fff;
      // transform: translateX(-2px);
      margin-left: px2rem(2px);
      line-height: px2rem(23px);
      font-size: px2rem(12px);
      font-weight: 600;
      color: #744b4b;
      text-align: center;
    }
  }

  .price {
    height: px2rem(16px);
    display: flex;
    align-items: center;
    border-radius: px2rem(8px);
    background: #fff;
    color: #744b4b;
    font-size: px2rem(12px);
    font-weight: 600;
    position: absolute;
    left: px2rem(2px);
    bottom: px2rem(9px);
    padding: 0 px2rem(9px);

    .total-price {
      transform: scale(0.83);
      margin-right: px2rem(9px);
    }

    .coin,
    .jewel {
      width: px2rem(10px);
      height: px2rem(10px);
    }

    .coin-price,
    .jewel-price {
      transform: scale(0.67);
    }
  }

  .right-container {
    width: px2rem(341px);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: px2rem(17px);

    .right-header {
      width: px2rem(300px);
      height: px2rem(29px);
      border-bottom-left-radius: px2rem(25px);
      border-bottom-right-radius: px2rem(25px);
      background: #e9d7b3;
      font-size: px2rem(16px);
      font-weight: 600;
      color: #744b4b;
      line-height: px2rem(29px);
      text-align: center;
      margin-bottom: px2rem(8px);
    }

    .main {
      width: px2rem(341px);
      flex: 1;
      border-radius: px2rem(7px);
      background: rgba(233, 215, 179, 0.5);
      border: 1px solid rgba(255, 251, 239, 0.5);
      overflow-y: scroll;

      .main-header {
        width: px2rem(341px);
        height: px2rem(44px);
        display: flex;
        justify-content: center;
        align-items: center;

        .type-item {
          height: px2rem(30px);
          padding: 0 px2rem(2px);

          img {
            width: px2rem(30px);
          }
        }
      }

      .image-box {
        display: flex;
        flex-wrap: wrap;
        margin-left: px2rem(20px);
        max-height: calc(100% - 44px);
        overflow-y: scroll;

        .sell-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: px2rem(13px);
          margin-bottom: px2rem(7px);

          .sell-item-image {
            width: px2rem(50px);
            height: px2rem(50px);
            background: #f1e4b7;
            box-shadow: inset 0 0 5px 0 rgba(152, 125, 72, 0.35);
            margin-bottom: px2rem(6px);

            img {
              width: 100%;
            }
          }
        }

        .image-item {
          margin-right: px2rem(13px);
          margin-bottom: px2rem(13px);

          .image-item-wrap {
            width: px2rem(50px);
            height: px2rem(50px);
            background: #f1e4b7;
            box-shadow: inset 0 0 5px 0 rgba(152, 125, 72, 0.35);

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .btn-box {
      height: px2rem(39px);
      display: flex;
      align-items: center;
      margin-top: px2rem(5px);

      .wardrobe,
      .shop {
        width: px2rem(135px);
        height: px2rem(23px);
        border-radius: px2rem(11px);
        line-height: px2rem(23px);
        font-size: px2rem(12px);
        text-align: center;
        color: #b28146;
        background: #e9d7b3;
      }

      .wardrobe {
        margin-right: px2rem(16px);
      }

      .btn-active {
        background: #d37645 !important;
        color: #fff !important;
      }
    }
  }
}

.selected-item {
  box-sizing: border-box;
  border: 2px solid rgba(211, 118, 69, 0.7);
  box-shadow: inset 0 0 10px 0 rgba(255, 255, 255, 1);
}

.purchased {
  font-size: px2rem(12px);
  transform: scale(0.83);
  color: #d6c197;
  margin: px2rem(7px) 0;
}

.sell-bi-price,
.sell-zuan-price {
  width: px2rem(50px);
  height: px2rem(11px);
  display: flex;
  align-items: center;
  background: #fffbef;
  color: #804f28;
  border-radius: px2rem(3px);
  margin-bottom: px2rem(5px);
}

.sell-bi-price > img,
.sell-zuan-price > img {
  width: px2rem(9px);
  height: px2rem(9px);
  margin-left: px2rem(2px);
}

.sell-bi-price > span,
.sell-zuan-price > span {
  flex: 1;
  text-align: center;
  font-size: px2rem(12px);
  transform: scale(0.67);
}
</style>
