/*
 * 全局环境配置
 * @api  后台api地址
 * @link 全局链接地址
 */
const CONFIG = {
  // baseURL: 'http://192.168.1.101:5000/',
  // baseURL: 'http://127.0.0.1:5000/',
  baseURL: 'http://test.co-study.com.cn/',  // 测试
  // baseURL: 'https://slb.co-study.com.cn/',  // 生产

  // coURL: 'https://slb.co-study.com.cn/',  // 生产
  coURL: 'http://test.co-study.com.cn/',  // 测试

  imageUrlPrefix: 'https://image.co-study.com.cn/app/image/figure/',
  webImageUrlPrefix: 'https://image.co-study.com.cn/web/user-figure/'
}

// http://anniversary-test.co-life.cn/

/**
 * 个人形象图片上传文件名格式说明：
 * 个人形象 站立 : 时间戳+五位数随机+7+userId  例子：1600936514513_25165_7_123.png
 * 个人形象 座着 : 时间戳+五位数随机+8+userId  例子：1600936514513_25165_8_123.png
 */

export default CONFIG
