import axios from 'axios'
import CONFIG from '../config'
import Vue from 'vue'
import { Toast } from 'vant'
import qs from 'qs'

Vue.use(Toast)

const config = {
  baseURL: CONFIG.baseURL,
  timeout: 5 * 1000,  // 请求超时时间
  headers: {
    // 'content-type': 'application/json'
  },
  validatorStatus(status) {
    return status >= 200 && status < 510
  },
  transformRequest: [(data) => {
    data = qs.stringify(data)       // 序列化
    return data     // return 必须是一个字符串，或 ArrayBuffer，或 Stream 
  }]
}

const http = axios.create(config)

http.interceptors.request.use(
  originConfig => {
    // const access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDU4NjM4NzEsIm5iZiI6MTYwNTg2Mzg3MSwianRpIjoiNjFhYmY1OTktMjE2My00OWQ0LThiM2YtMGQ1YmEzNGZkMzg1IiwiZXhwIjoxNjA4NDU1ODcxLCJpZGVudGl0eSI6ImZkYzcyZTBlLTgwNGMtNGI1MC05ODU2LTUyZGM5ZGM3ZWU2MSIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.-Nkl8MSAXMJcTw0dGCK888_ZVxwlhxVUVZycFcfrliM'
    const access_token = localStorage.getItem('access_token')
    // || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDU4NjM4NzEsIm5iZiI6MTYwNTg2Mzg3MSwianRpIjoiNjFhYmY1OTktMjE2My00OWQ0LThiM2YtMGQ1YmEzNGZkMzg1IiwiZXhwIjoxNjA4NDU1ODcxLCJpZGVudGl0eSI6ImZkYzcyZTBlLTgwNGMtNGI1MC05ODU2LTUyZGM5ZGM3ZWU2MSIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.-Nkl8MSAXMJcTw0dGCK888_ZVxwlhxVUVZycFcfrliM'
    const Authorization = `Bearer ${access_token}`
    const reqConfig = { ...originConfig }
    reqConfig.headers.Authorization = Authorization
    return reqConfig
  }
)

http.interceptors.response.use(
  res => {
    if(res.status.toString().startsWith('2') && res.data.errCode === 0) {
      return res.data
    }
    const { msg } = res
    Toast({message: msg || res.data.msg,className: 'toast-trans'})
  },

  error => {
    if (!error.response) {
      Toast({message:'请检查 API 是否异常', className: 'toast-trans'})
    }

    //  判断请求超时
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      Toast({message: '请求超时', className:'toast-trans'})
    }

    return Promise.reject(error)
  }
)

export default http
