import Config from '../config'
const webImageUrlPrefix = Config.webImageUrlPrefix

const categoryList = [{
  type: 'all',
  iconUrl: `${webImageUrlPrefix}all.png`,
  selectedIconUrl: `${webImageUrlPrefix}all-s.png`
},
{
  type: 'expression',
  iconUrl: `${webImageUrlPrefix}expression.png`,
  selectedIconUrl: `${webImageUrlPrefix}expression-s.png`
},
{
  type: 'bangs',
  iconUrl: `${webImageUrlPrefix}bangs.png`,
  selectedIconUrl: `${webImageUrlPrefix}bangs-s.png`
},
{
  type: 'hairstyle',
  iconUrl: `${webImageUrlPrefix}hairstyle.png`,
  selectedIconUrl: `${webImageUrlPrefix}hairstyle-s.png`
},
{
  type: 'jacket',
  iconUrl: `${webImageUrlPrefix}jacket.png`,
  selectedIconUrl: `${webImageUrlPrefix}jacket-s.png`
},
{
  type: 'pants',
  iconUrl: `${webImageUrlPrefix}pants.png`,
  selectedIconUrl: `${webImageUrlPrefix}pants-s.png`
},
{
  type: 'socks',
  iconUrl: `${webImageUrlPrefix}socks.png`,
  selectedIconUrl: `${webImageUrlPrefix}socks-s.png`
},
{
  type: 'shoes',
  iconUrl: `${webImageUrlPrefix}shoes.png`,
  selectedIconUrl: `${webImageUrlPrefix}shoes-s.png`
},
{
  type: 'accessories',
  iconUrl: `${webImageUrlPrefix}accessories.png`,
  selectedIconUrl: `${webImageUrlPrefix}accessories-s.png`
}]

export default categoryList
