import Vue from 'vue'
import App from './App.vue'

import Utils from '../src/util/util'
Utils.getAppToken()

import { Toast, Loading } from 'vant'
import 'vant/lib/index.css'
Vue.use(Toast)
Vue.use(Loading)

import './assets/style/index.scss'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
