const Utils = {}

Utils.isIosOrAndroid = function () {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  let isStr = ''
  if (isAndroid) {
    isStr = 'android'
  }
  if (isiOS) {
    isStr = 'ios'
  }
  return isStr
}

Utils.getAppToken = () => {
  const url = window.location.href
  let params = {}
  if (url.indexOf('access_token') !== -1) {
    const params_str = url.split('?')[1]
    const params_arr = params_str.split('&')
    params_arr.forEach(item => {
      params[item.split('=')[0]] = item.split('=')[1]
    })
    localStorage.setItem('access_token', params.access_token)
    localStorage.setItem('language', params.language)
  }
  // language: zh是中文
  //           en是英文
}

/**
 * @desc 获取n位随机字符窜
 * @param {Number} n
 */
Utils.getRandomStr = (n) => {
  let str = ""
  const chars =
    "1234567890"
    // "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890"
  for (let i = 0; i < n; i++) {
    // str += chars.charAt(Math.floor(Math.random() * 62))
    str += chars.charAt(Math.floor(Math.random() * 10))
  }
  return str
}

export default Utils
