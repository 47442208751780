<template>
  <div class="popup-container">
    <div class="popup-content">
      <div class="close-img-wrap" @click="handleClose">
        <img :src="`${webImageUrlPrefix}close.png`" />
      </div>
      <div class="popup-cloud-img">
        <img :src="`${webImageUrlPrefix}cloud.png`" />
      </div>
      <div class="main">
        <div class="title">想得到 Co 币，看这里！！</div>
        <div class="row">
          <span class="serial">1.</span>
          <span>通过</span>
          <span class="highlight">成功专注</span>
          <span>获得 Co 币。成功专注指倒计时结束，手动点击开始休息。</span>
        </div>
        <div class="row">
          <span class="serial">2.</span>
          <span style="line-height: 0.03rem;">专注模式不同，货币发放比例不同。</span>
          <br />
          <span class="highlight" style="margin-left:0.029rem;">深度模式</span>
          <span>不允许使用其他软件，每成功专注</span>
          <span class="highlight" style="line-height: 0.05rem;">1min可获得2 Co 币。</span>
          <br />
          <span class="highlight" style="margin-left:0.029rem;">白名单模式</span>
          <span>可以设置使用部分软件，每成功专注</span>
          <span class="highlight" style="line-height: 0.05rem;">1min可获得1 Co币。</span>
          <br />
          <span class="highlight" style="margin-left:0.029rem;">轻度模式</span>
          <span>允许使用其他软件，每成功专注</span>
          <span class="highlight">2min可获得1 Co 币</span>
          <br />
        </div>
        <div class="row">
          <span class="serial">3.</span>
          <span class="content-smallsize">Co 币获取上限为一天</span>
          <span class="highlight">1200 Co币</span>
        </div>
        <div class="example">e.g.若当天已获得1100 Co 币，则不论下一次专注多久，只能得到100 Co 币。</div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../config'

export default {
  data() {
    return {
      webImageUrlPrefix: Config.webImageUrlPrefix,
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  $rem: 6.67px;
  @return ($px/$rem) + rem;
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: constant(safe-area-inset-left);
  right: env(safe-area-inset-left);

  .close-img-wrap {
    width: px2rem(51px);
    height: px2rem(51px);
    position: absolute;
    top: px2rem(-18px);
    right: px2rem(-18px);

    img {
      width: 100%;
    }
  }

  .popup-cloud-img {
    width: px2rem(449px);
    position: absolute;
    bottom: px2rem(-20px);

    img {
      width: 100%;
    }
  }

  .main {
    width: px2rem(367px);
    height: px2rem(230px);
    background: #ffeccc;
    border-top-left-radius: px2rem(20px);
    border-top-right-radius: px2rem(20px);
    padding: px2rem(17px) px2rem(30px);
    box-sizing: border-box;
    color: #51321e;
    font-size: px2rem(12px);

    .title {
      font-size: px2rem(15px);
      font-weight: 600;
      color: #c39139;
      text-align: center;
      text-shadow: 2px 2px #fff, 2px -2px #fff, 2px 0 #fff, 0 2px #fff,
        -2px 0 #fff, 0 -2px #fff, -2px -2px #fff;
      margin-bottom: px2rem(7px);
    }

    .row {
      transform-origin: left;
      transform: scale(0.75);
      white-space: nowrap;

      .serial {
        font-size: px2rem(18px);
      }

      .highlight {
        font-size: px2rem(15px);
        font-weight: 600;
        color: #c39139;
      }
    }

    .example {
      transform-origin: left;
      transform: scale(0.58);
      white-space: nowrap;
      margin-left: px2rem(16px);
      margin-top: px2rem(3px);
    }
  }
}
</style>
